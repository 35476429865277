import React from "react";
import Footer from "../components/Footer.js";
import InfoSection from "../components/InfoSection.js";
import { Outlet, Link } from "react-router-dom";

const Home = () => {
    return (
        <>
            <main className="main">
                <InfoSection
                    headerClass="info-header"
                    src="https://firebasestorage.googleapis.com/v0/b/askvikdevelopmentwebsite.appspot.com/o/Website%2Fphone-with-pink-background.jpg?alt=media&token=8e344787-a8a3-4ae7-82e1-47f8c625edaf"
                    imgClass="phone-pink-background info-crop"
                    heading={[
                        "Askvik Development",
                        <b> makes lifestyle apps</b>,
                    ]}
                    paragraph={[
                        "Our goal is to make useful and user-friendly native apps for iOS and Android. Whether it's a simple calculator or a complex learning app, ",
                        <strong>
                            we're commited to crafting great user experiences.
                        </strong>,
                    ]}
                    linkTo="/about"
                    buttonText="Our Approach to Great User
                    Experiences"
                />
                <InfoSection
                    headerClass="info-header row-reverse"
                    src="https://firebasestorage.googleapis.com/v0/b/askvikdevelopmentwebsite.appspot.com/o/Website%2Ffood-win-promo.jpg?alt=media&token=71d66fa6-c002-4506-9cb5-9a00ebbe5275"
                    imgClass="foodWinPromo"
                    heading={["FoodWin is our", <b> first </b>, "project!"]}
                    paragraph={[
                        "Food management. ",
                        <strong>Made easy.</strong>,
                    ]}
                    linkTo="/products/foodwin"
                    buttonText="Learn More"
                />
            </main>
            <Footer />
        </>
    );
};

export default Home;
