import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home.js";
import Products from "./pages/Products.js";
import About from "./pages/About.js";
import Navbar from "./components/Navbar.js";
import FoodWin from "./pages/FoodWin.js";
import Privacy from "./pages/Privacy.js";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navbar />}>
                    <Route index element={<Home />} />
                    <Route path="products" element={<Products />} />
                    <Route path="/products/foodwin" element={<FoodWin />} />
                    <Route path="about" element={<About />} />
                    <Route path="privacy" element={<Privacy />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
