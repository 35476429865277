import React from "react";

const About = () => {
    return (
        <>
            <h1 className="cvh">COMING SOON!</h1>
        </>
    );
};

export default About;
