import React from "react";
import { Outlet } from "react-router-dom";
import Product from "../components/Product";

const Products = (props) => {
    return (
        <>
            <Product />
            <Outlet />
        </>
    );
};

export default Products;
