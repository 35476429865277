import React from "react";
import { Outlet, Link } from "react-router-dom";

const Product = () => {
    return (
        <div className="product-tile">
            <div className="app-icon">
                <Link to="/products/foodwin">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/askvikdevelopmentwebsite.appspot.com/o/foodwinlogo512.png?alt=media&token=58a4b6d6-41f8-4dda-a8f5-36f454e3097d"
                        className="app-icon-logo"
                    />
                </Link>
            </div>
        </div>
    );
};

export default Product;
