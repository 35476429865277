import React from "react";
import logo from "../images/askvikdevelopment_logo.png";
import { Outlet, Link } from "react-router-dom";

const Navbar = () => {
    return (
        <>
            <nav>
                <ul className="navUl">
                    <li className="navLi">
                        <Link to="/">
                            <img src={logo} alt="logo" height="22px" />
                        </Link>
                    </li>
                    <li id="products" className="navLi">
                        <Link to="/products">Products</Link>
                    </li>
                    <li id="about" className="navLi">
                        <Link to="/about">About Us</Link>
                    </li>
                </ul>
            </nav>

            <Outlet />
        </>
    );
};

export default Navbar;
